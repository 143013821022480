.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  font-family: Arial, sans-serif;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  /* width: 300px; */
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0069d9;
}


.Node {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #e9ecef;
  border-radius: 50%;
  margin: 10px;
  font-size: 24px;
  font-weight: bold;
}

.legend-box {
  display: inline-block;
  width: 20px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0.5;
}

.red-box {
  background-color: red;
}

.green-box {
  background-color: green;
}

.blue-box {
  background-color: blue;
}

body {
  background-color: #f8f9fa;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.main-container {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  max-width: 1200px;
  padding: 40px;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin-bottom: 1rem;
}

p, ul, ol, li, form {
  margin-bottom: 1rem;
}

button:hover {
  opacity: 0.9;
  cursor: pointer;
}
